
import ArrowsSvg from '@/assets/viewer/Arrows.svg.vue'
import BoxSvg from '@/assets/viewer/Box.svg.vue'
import ChatWithBadge from '@/assets/viewer/ChatWithBadge.vue'
import EyeSvg from '@/assets/viewer/Eye.svg.vue'
import HistorySvg from '@/assets/viewer/History.svg.vue'
import FloatingTabs from '@/components/FloatingTabs/FloatingTabs.vue'
import CubeLoader from '@/components/Loaders/CubeLoader.vue'
import {
  commentStore,
  fetchComments,
  getCommentLoading,
  setCommentsSecondary,
} from '@/modules/comment'
import { WarehouseOptimization } from '@/modules/graphql/graphql.types'
import {
  CompareOptions,
  HistoryItem,
  downloadHistoryItem,
  getHistoryLoading,
  historyStore,
  resetHistoryError,
} from '@/modules/history'
import { SteelspaceModel, modelStore } from '@/modules/model'
import { getOnstockLoading, onStockStore } from '@/modules/onstock'
import {
  compareWarehouseOptimization,
  getLatestWarehouseOptimization,
  getWarehouseLoading,
  getWarehouseOptimizationForHistoryItem,
  setWarehouseOptimization,
  setWarehouseOptimizationSecondary,
} from '@/modules/warehouse'
import {
  ModelStateNumber,
  cameraMode,
  clearRenderContext,
  clearSecondaryModel,
  drawCompareView,
  getGizmoColorsBasedOnTheme,
  isometricCamera,
  modelViewerStore,
  removeActiveLoad,
  removeFloatingCommentInputs,
  removeSelection,
  resetCamera,
  setAddedItems,
  setCameraMode,
  setChangedItems,
  setCommentFocusOnObjectOption,
  setCommentShowCreateCommentOption,
  setCompareFadingLevel,
  setDeletedItems,
  setIsolation,
  setLabelShowAddLabelOption,
  setMoveCameraToSelectionOption,
  setPrimaryModelNumber,
  setRenderContext,
  setSecondaryModelNumber,
  setUnselectedMembersToTransparentOption,
  toggleComments,
  toggleLabels,
} from '@/store/modelViewer.store'
import { FloatingComment, FloatingLabel } from '@/types'
import {
  CsBreadcrumbs,
  CsBtn,
  CsBtnToggle,
  CsCompareDialog,
  CsFlex,
  CsSlider,
} from '@consteel/csuetify'
import { CompareView, RenderContext } from '@consteel/straw'
import Vue from 'vue'
import WarehouseSvg from '../../assets/viewer/Warehouse.svg.vue'
import FloatingCommentVue from './Comments/FloatingComment.vue'
import FloatingCommentInputVue from './Comments/FloatingCommentInput.vue'
import CommentTab from './CommentTab.vue'
import DisplayTab from './DisplayTab.vue'
import FailedToLoadModel from './FailedToLoadModel.vue'
import FilterTab from './FilterTab.vue'
import HistoryTab from './HistoryTab.vue'
import {
  DefaultLabel,
  LineLoadLabel,
  LoadTransferSurfaceLabel,
  NodalLoadLabel,
  StructuralMemberLabel,
  StructuralPlateLabel,
  SupportPointLabel,
  SurfaceLoadLabel,
} from './Labels'
import LoadTab from './LoadTab.vue'
import WarehouseOptimizationTab from './WarehouseOptimizationTab.vue'

export default Vue.extend({
  name: 'HistoryCompareTab',
  components: {
    FloatingCommentVue,
    FloatingCommentInputVue,
    CubeLoader,
    CsBreadcrumbs,
    CsBtn,
    CsBtnToggle,
    CsFlex,
    DefaultLabel,
    FilterTab,
    FloatingTabs,
    LineLoadLabel,
    LoadTransferSurfaceLabel,
    NodalLoadLabel,
    StructuralMemberLabel,
    StructuralPlateLabel,
    SupportPointLabel,
    SurfaceLoadLabel,
    HistoryTab,
    CsSlider,
    FailedToLoadModel,
    WarehouseOptimizationTab,
    CsCompareDialog,
  },
  props: {
    breadcrumbItems: {
      type: Array as () => Array<{
        text: string
        disabled: boolean
      }>,
    },
    floatingLabels: {
      type: Array as () => FloatingLabel[],
    },
    activeLoadId: String,
    model: Object as () => SteelspaceModel | null,
    historyItems: Array as () => HistoryItem[],
  },
  data() {
    return {
      showCompareDialog: true,
      selectedHistoryItemId: null as string | null,
      secondarySelectedHistoryItemId: null as string | null,
      selectToCompare: true,
    }
  },
  async beforeDestroy() {
    setChangedItems(null)
    setAddedItems(null)
    setDeletedItems(null)
    clearSecondaryModel()
    setPrimaryModelNumber(null)
    setSecondaryModelNumber(null)
    setWarehouseOptimizationSecondary(null)
    removeSelection()
    await removeFloatingCommentInputs()
    setCompareFadingLevel(0)
    setCommentsSecondary(null)
    clearRenderContext()
  },
  computed: {
    numberOfUnreadComments(): number {
      let unreadComments = 0
      commentStore.comments.forEach((comment) => {
        const isCommentUnreadByUser = commentStore.unreads.find(
          (unread) => unread.commentId === comment.id
        )
        if (isCommentUnreadByUser) unreadComments++
      })
      return unreadComments
    },
    floatingComments(): FloatingComment[] {
      return modelViewerStore.floatingComments
    },
    floatingCommentsSecondary(): FloatingComment[] {
      return modelViewerStore.floatingCommentsSecondary
    },
    labelVisibility(): boolean {
      return modelViewerStore.labelVisibility
    },
    viewControlVisibility(): boolean {
      return modelViewerStore.viewControlVisibility
    },
    cantLoad(): boolean {
      return modelViewerStore.cantLoad
    },
    unselectedMembersToTransparentOption(): boolean {
      return modelViewerStore.unselectedMembersToTransparentOption
    },
    errorMessage(): string {
      return modelViewerStore.errorMessage
    },
    historyLoading(): boolean {
      return getHistoryLoading()
    },
    warehouseLoading(): boolean {
      return getWarehouseLoading()
    },
    loading(): boolean {
      return (
        this.historyLoading ||
        modelStore.loading ||
        getOnstockLoading() ||
        getCommentLoading() ||
        this.warehouseLoading
      )
    },
    modelLoading(): boolean {
      return modelViewerStore.currentViewLoading
    },
    fadingLevel: {
      get() {
        return modelViewerStore.compareFadingLevel
      },
      set(value: number) {
        setCompareFadingLevel(value)
      },
    },
    compareLabels(): Array<string> {
      return [
        this.selectedHistoryItem?.number || `#${this.historyItems.length + 1}`,
        this.secondarySelectedHistoryItem?.number || `#${this.historyItems.length + 1}`,
      ]
    },
    selectedHistoryItem(): HistoryItem | SteelspaceModel | undefined {
      if (this.selectedHistoryItemId == null) return

      if (this.model && this.selectedHistoryItemId === this.model.id) return this.model

      return this.historyItems.find((item) => item.id === this.selectedHistoryItemId)
    },
    secondarySelectedHistoryItem(): HistoryItem | SteelspaceModel | undefined {
      if (this.secondarySelectedHistoryItemId == null) return

      if (this.model && this.secondarySelectedHistoryItemId === this.model.id) return this.model

      return this.historyItems.find((item) => item.id === this.secondarySelectedHistoryItemId)
    },
    selectedCameraMode: {
      get() {
        return modelViewerStore.cameraMode
      },
      set(newVal: cameraMode) {
        setCameraMode(newVal)
      },
    },
    secondaryTabs(): Array<Record<string, unknown>> {
      const tabs = [
        {
          icon: HistorySvg,
          text: this.$t('History'),
          content: HistoryTab,
          disabled: this.loading,
          props: {
            selectToCompare: this.selectToCompare,
            secondarySelected: this.secondarySelectedHistoryItemId,
            model: this.model,
            value: this.selectedHistoryItemId,
            items: this.historyItems,
            errorMessage: this.$t(historyStore.historyErrorMessage),
            errorTitle: this.$t(historyStore.historyErrorTitle),
            errorIcon: historyStore.historyErrorIcon,
            errorIconColor: historyStore.historyErrorIconColor,
            loading: this.historyLoading,
            hideEdit: true,
            selectable: false,
            hideOpen: true,
            hideCreate: true,
            hideRestore: true,
            hideDownload: true,
            hideDelete: true,
            compareVersionsButtonText: this.$t('New compare'),
          },
          listeners: {
            input: this.handleHistoryItemSelect,
            download: downloadHistoryItem,
            selectSecondary: this.handleHistoryItemSelectSecondary,
            errorCancel: resetHistoryError,
            clickCompareVersions: this.handleClickNewCompareVersions,
          },
        },
        {
          icon: BoxSvg,
          text: this.$t('Selection'),
          content: FilterTab,
          disabled: this.loading,
        },
        {
          icon: ArrowsSvg,
          text: this.$t('Loads'),
          content: LoadTab,
          disabled: this.loading,
        },
        {
          icon: EyeSvg,
          text: this.$t('Display'),
          content: DisplayTab,
          disabled: this.loading || this.modelLoading,
          listeners: {
            toggleLabels,
            toggleComments,
            setLabelShowAddLabelOption,
            setCommentShowCreateCommentOption,
            setCommentFocusOnObjectOption,
            setUnselectedMembersToTransparentOption,
            setMoveCameraToSelectionOption,
          },
        },
        {
          icon: WarehouseSvg,
          text: this.$t('Warehouse'),
          content: WarehouseOptimizationTab,
          disabled: this.loading,
        },
        {
          icon: ChatWithBadge,
          text: this.$t('Comments'),
          disabled: this.loading,
          iconProps: {
            badgeContent: this.numberOfUnreadComments,
          },
          content: CommentTab,
          props: {
            model: this.model,
            numberOfUnreadComments: this.numberOfUnreadComments,
          },
        },
      ]

      return tabs
    },
  },
  methods: {
    setFocusToCanvas() {
      if (!modelViewerStore.cantLoad) {
        ;(this.$refs.canvas as HTMLCanvasElement).focus()
      }
    },
    getSelectedCompareItemByIndex(index: number): string | undefined {
      if (this.selectedHistoryItemId == null || this.secondarySelectedHistoryItemId == null) return

      const compareItems = { 0: this.selectedHistoryItemId, 1: this.secondarySelectedHistoryItemId }

      return compareItems[index]
    },
    handleClickClose(): void {
      this.$emit('navigateToModel')
    },
    async handleClickCompare({
      firstItemId,
      secondItemId,
      compareOptions,
    }: {
      firstItemId: string
      secondItemId: string
      compareOptions: CompareOptions
    }): Promise<void> {
      setCompareFadingLevel(0)
      if (!firstItemId || !secondItemId) {
        console.error('FirstItemId or SecondItemId is not given to compare')
        return
      }

      let primaryItemId = ''
      let secondaryItemId = ''

      if (!onStockStore.onStockModel) {
        console.error('OnStockModel not found by params modelId')
        return
      }

      try {
        let firstWarehouseOptimization: undefined | WarehouseOptimization
        let secondWarehouseOptimization: undefined | WarehouseOptimization

        const isFirstItemModel = this.$route.params.modelId === firstItemId
        const isSecondItemModel = this.$route.params.modelId === secondItemId

        if (isFirstItemModel) {
          firstWarehouseOptimization = await getLatestWarehouseOptimization()
        } else {
          firstWarehouseOptimization = await getWarehouseOptimizationForHistoryItem(
            onStockStore.onStockModel.steelspaceId.toString(),
            firstItemId
          )
        }

        if (isSecondItemModel) {
          secondWarehouseOptimization = await getLatestWarehouseOptimization()
        } else {
          secondWarehouseOptimization = await getWarehouseOptimizationForHistoryItem(
            onStockStore.onStockModel.steelspaceId.toString(),
            secondItemId
          )
        }

        if (
          firstWarehouseOptimization?.creationDate > secondWarehouseOptimization?.creationDate ||
          isFirstItemModel
        ) {
          setWarehouseOptimizationSecondary(firstWarehouseOptimization || null)
          setWarehouseOptimization(secondWarehouseOptimization || null)
          secondaryItemId = firstItemId
          primaryItemId = secondItemId
        } else {
          setWarehouseOptimizationSecondary(secondWarehouseOptimization || null)
          setWarehouseOptimization(firstWarehouseOptimization || null)
          secondaryItemId = secondItemId
          primaryItemId = firstItemId
        }
      } catch (error: any) {
        console.error({ error })
      }

      const changedWarehouseOptimizationItems = compareWarehouseOptimization()

      this.showCompareDialog = false

      this.$emit(
        'historyItemsCompare',
        primaryItemId,
        secondaryItemId,
        compareOptions,
        changedWarehouseOptimizationItems,
        async () => {
          await drawCompareView(
            this.$refs.canvas as HTMLCanvasElement,
            this.$refs.canvasParent as HTMLDivElement,
            this.$vuetify.theme.dark
          )
        }
      )

      const modelId = this.$route.params.modelId
      const historyIdPrimary = modelId === primaryItemId ? undefined : primaryItemId
      const historyIdSecondary = modelId === secondaryItemId ? undefined : secondaryItemId

      await fetchComments(modelId, historyIdPrimary, ModelStateNumber.primary)
      await fetchComments(modelId, historyIdSecondary, ModelStateNumber.secondary)

      this.$router.push({
        path: '/model/' + this.$route.params.modelId + '/compare',
        query: {
          current: primaryItemId,
          incoming: secondaryItemId,
        },
      })
    },
    handleClickNewCompareVersions(): void {
      this.showCompareDialog = true
    },
    async handleHistoryItemSelectSecondary(historyItemId: string): Promise<void> {
      this.secondarySelectedHistoryItemId = historyItemId
    },
    async handleHistoryItemSelect(historyItemId: string): Promise<void> {
      this.selectedHistoryItemId = historyItemId
      this.$emit('historyItemSelect', historyItemId)
    },
    resetCamera,
    isometricCamera,
  },
  mounted(): void {
    const context = new RenderContext(
      this.$refs.canvas as HTMLCanvasElement,
      this.$refs.canvasParent as HTMLDivElement
    )
    context.RenderSecondaryScene()
    context.RenderGizmo(getGizmoColorsBasedOnTheme(this.$vuetify.theme.dark))
    removeActiveLoad()
    setRenderContext(context)
  },
  created(): void {
    removeSelection()
    this.selectedHistoryItemId = (this.$route.query?.current as string) || this.model?.id || null
    this.secondarySelectedHistoryItemId =
      (this.$route.query?.incoming as string) || this.model?.id || null
  },
  watch: {
    async fadingLevel(val: number) {
      const view = modelViewerStore.currentView
      if (!view) return
      ;(view as CompareView).SetFadeLevel(val / 100)
    },
    $route(newRoute) {
      this.selectedHistoryItemId = newRoute.query?.current || this.model?.id || null
      this.secondarySelectedHistoryItemId = newRoute.query?.incoming || this.model?.id || null
    },
    unselectedMembersToTransparentOption() {
      if (this.unselectedMembersToTransparentOption) setIsolation(modelViewerStore.selectedIds)
      else setIsolation([])
    },
  },
})
